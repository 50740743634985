import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import "~/tailwind.css";
import type {
  MetaFunction,
  LinksFunction,
  LoaderFunctionArgs,
} from "@remix-run/node";
import { json } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useLocation,
  useNavigate,
  useRevalidator,
  useRouteError,
} from "@remix-run/react";
import { createServerClient } from "./lib/supabase.server";
import { createUserSession } from "./lib/session.server";
import { useEffect, useState } from "react";
import { createBrowserClient } from "@supabase/auth-helpers-remix";
import type { Database, TypesafeClient, UserPreferences } from "./types";
import { Button, Toaster } from "./components/ui";
import { TrpcProvider } from "./context/trpc-provider";
import { SupabaseProvider } from "./context/supabase-provider";
import { TrackingEngineProvider } from "./context/tracking-engine-provider";
import { cn } from "./lib/utils";
import { GoogleAnalytics, GoogleNoScript } from "./components/GoogleAnalytics";
import { SESSION_KEYS } from "./constants";

export const links: LinksFunction = () => [
  {
    id: "google-font",
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap",
  },
];

export async function loader({ request }: LoaderFunctionArgs) {
  const response = new Response();
  const supabase = createServerClient(request, response);

  const {
    data: { session },
  } = await supabase.auth.getSession();

  const {
    data: { user },
  } = await supabase.auth.getUser();

  const {
    device_id,
    session: cookieSession,
    cookie,
  } = await createUserSession(request, user?.id);

  response.headers.append("set-cookie", cookie);

  const { hostname } = new URL(request.url);

  return json(
    {
      session,
      user,
      deviceId: device_id,
      domain: hostname.split(".").slice(-2).join("."),
      theme: cookieSession.get(SESSION_KEYS.theme) ?? "light",
      env: {
        NODE_ENV: process.env.NODE_ENV,
        SUPABASE_URL: process.env.SUPABASE_URL,
        SUPABASE_ANON_KEY: process.env.SUPABASE_ANON_KEY,
        PUBLIC_STRIPE_KEY: process.env.PUBLIC_STRIPE_KEY,
        PUBLIC_MAPBOX_KEY: process.env.PUBLIC_MAPBOX_KEY,
        PUBLIC_MAPTILER_KEY: process.env.PUBLIC_MAPTILER_KEY,
        GA_TRACKING_ID: process.env.GA_TRACKING_ID,
        GTM_TRACKING_ID: process.env.GTM_TRACKING_ID,
        FACEBOOK_PIXEL_ID: process.env.FACEBOOK_PIXEL_ID,
        ENABLE_TRACKING: process.env.ENABLE_TRACKING === "true",
        INTERCOM_APP_ID: process.env.INTERCOM_APP_ID,
        APP_PROTOCOL: process.env.APP_PROTOCOL,
        RAILWAY_PUBLIC_DOMAIN: process.env.RAILWAY_PUBLIC_DOMAIN,
      },
    },
    {
      headers: response.headers,
    }
  );
}

export const meta: MetaFunction<typeof loader> = ({ data, location }) => {
  return [
    { property: "twitter:card", content: "summary_large_image" },
    { property: "twitter:site", content: "@cartamaps_" },
    { property: "twitter:creator", content: "@francoxavier33" },
    { property: "og:type", content: "website" },
    {
      property: "og:url",
      content: `${data?.env.RAILWAY_PUBLIC_DOMAIN}${location.pathname}`,
    },
  ];
};

function App() {
  const { env, session, deviceId, theme, domain } =
    useLoaderData<typeof loader>();
  const { revalidate } = useRevalidator();
  const navigate = useNavigate();

  const [supabase] = useState<TypesafeClient>(() =>
    createBrowserClient<Database>(env.SUPABASE_URL, env.SUPABASE_ANON_KEY, {
      cookieOptions: {
        domain: domain,
        path: "/",
        sameSite: "lax",
        secure: env.NODE_ENV === "production",
      },
    })
  );

  const serverAccessToken = session?.access_token;

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (
        event !== "INITIAL_SESSION" &&
        session?.access_token !== serverAccessToken
      ) {
        // server and client are out of sync.
        revalidate();
      }

      if (
        event === "PASSWORD_RECOVERY" &&
        window.location.pathname !== "/recovery"
      ) {
        return navigate("/recovery");
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [serverAccessToken, supabase, revalidate, navigate]);

  return (
    <SupabaseProvider supabase={supabase}>
      <TrpcProvider>
        <TrackingEngineProvider deviceId={deviceId}>
          <Document deviceId={deviceId} theme={theme}>
            <Outlet />
            <script
              dangerouslySetInnerHTML={{
                __html: `window.env = ${JSON.stringify(env)}`,
              }}
            />
          </Document>
        </TrackingEngineProvider>
      </TrpcProvider>
    </SupabaseProvider>
  );
}

export default withSentry(App);

function Document({
  deviceId,
  theme,
  children,
}: {
  deviceId: string;
  theme: UserPreferences["theme"];
  children: React.ReactNode;
}) {
  const location = useLocation();
  const isEmbedded =
    location.pathname.includes("embedded") ||
    location.pathname.includes("share");
  return (
    <html
      lang="en"
      suppressHydrationWarning={true}
      className={cn(
        isEmbedded && "carta-map-embed",
        theme === "dark" && "dark"
      )}
    >
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=1,viewport-fit=cover"
        />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
        <Meta />
        <Links />
        <GoogleAnalytics deviceId={deviceId} isEmbedded={isEmbedded} />
        {isEmbedded && (
          <link
            rel="stylesheet"
            href={`${location.pathname.replace(/share|embedded/, "css")}.css`}
          />
        )}
      </head>
      <body className={cn(isEmbedded && "carta-map-embed")}>
        <GoogleNoScript />
        {children}
        <Toaster />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  const renderErrorMarkup = () => {
    if (isRouteErrorResponse(error)) {
      return (
        <>
          <div className="flex items-center gap-x-2 text-sm text-primary uppercase tracking-wider font-medium mb-8 border px-2 py-1.5 rounded-md bg-background border-primary">
            <span className="h-2 w-2 rounded-full bg-primary overflow-hidden"></span>
            <p>{error.status} error</p>
          </div>
          <h1 className="text-7xl font-sans font-semibold mb-8 leading-none">
            {error.status === 404
              ? "We can't find that page"
              : "Sorry, something went wrong."}
          </h1>
          <p className="text-lg text-muted-foreground mb-12">
            {error.statusText
              ? error.statusText
              : "Sorry, the page you are looking for doesn't exist or has been moved."}
          </p>
          <Button asChild>
            <a href="/">
              <div className="h-full flex items-center justify-center font-bold">
                Back Home
              </div>
            </a>
          </Button>
        </>
      );
    }

    let errorMessage: string = "Unknown error";
    if (error instanceof Error && "message" in error) {
      errorMessage = error.message as string;
    }

    return (
      <>
        <div className="flex items-center gap-x-2 text-sm text-primary uppercase tracking-wider font-medium mb-8 border px-2 py-1.5 rounded-md bg-background border-primary">
          <span className="h-2 w-2 rounded-full bg-primary overflow-hidden"></span>
          <p>Oh No</p>
        </div>
        <h1 className="text-7xl font-sans font-semibold mb-4">
          Sorry, something went wrong.
        </h1>
        <p className="text-lg text-muted-foreground mb-8">{errorMessage}</p>
        <Button asChild>
          <a href="/">
            <div className="h-full flex items-center justify-center font-bold">
              Back Home
            </div>
          </a>
        </Button>
      </>
    );
  };

  captureRemixErrorBoundaryError(error);

  return (
    <html>
      <head>
        <title>Oops!</title>
        <Meta />
        <Links />
      </head>
      <body className="h-screen w-screen bg-muted flex items-center justify-start">
        <div className="container max-w-screen-md flex flex-col items-start">
          {renderErrorMarkup()}
        </div>
        <Scripts />
      </body>
    </html>
  );
}
