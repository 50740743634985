import { useEffect } from "react";
import { GA_TRACKING_ID, GTM_TRACKING_ID } from "~/lib/env";

interface GoogleAnalyticsProps {
  deviceId: string;
  nonce?: string;
  isEmbedded?: boolean;
}

export const GoogleAnalytics = ({
  isEmbedded,
  ...props
}: GoogleAnalyticsProps) => {
  useEffect(() => {
    if ("gtag" in window) {
      return;
    }
    const gtag = document.createElement("script");
    gtag.async = true;
    gtag.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;

    const ga = document.createElement("script");
    ga.async = true;
    ga.innerHTML = `window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('set', 'linker', {'domains': ['app.cartamaps.com', 'cartamaps.com']});
gtag('js', new Date());
gtag('config', '${GA_TRACKING_ID}', { user_id: '${props.deviceId}' });`;

    const tagManager = document.createElement("script");
    tagManager.async = true;
    tagManager.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GTM_TRACKING_ID}');`;

    document.head.appendChild(gtag);
    document.head.appendChild(ga);
    document.head.appendChild(tagManager);
  }, [props.deviceId]);

  return null;
};

export const GoogleNoScript = () => {
  return (
    <noscript suppressHydrationWarning={true}>
      <iframe
        title="google-tagmanager-noscript"
        src={`https://www.googletagmanager.com/ns.html?id=${GTM_TRACKING_ID}`}
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      ></iframe>
    </noscript>
  );
};
